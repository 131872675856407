.ui.vertical.sidebar.menu {
  > .item {
    border-top: solid 1px #62AB9E;
  }
}

.ui.top.sidebar.mobilemenu {
  background-color: #000000;
}

.sidebar .servicelink {
  border-top: 1px solid #62AB9E;

  a {
    color: #ffffff;
  }
}
